import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  GfFlyerDto,
  GfFlyerItemDto,
  GfFlyerItemList,
} from '@swagger/typescript-fetch-goflyer';
import { TopDivView } from './TopDiv';
import CustomHelmet from '../Helmet';
import { messages } from 'locales/messages';
import { useTranslation } from 'react-i18next';
import CanvasDrawComponentMobile, {
  CanvasComponentHandle,
} from 'app/mobile-desktop-common-components/CanvasDrawComponent/CanvasDrawComponentMobile ';
import { checkIfFlyerItemInShoppingList } from 'utils/dtoUtility';
import { GoFlyerAppContext } from 'app/store/context';
import { Spinner } from '../Spinner';

interface FlyerDetailViewInterface {
  flyer: GfFlyerDto;
  handleShare: (params: any) => Promise<void>;
}

export const NewDrawFlyerView = memo(
  ({ flyer, handleShare }: FlyerDetailViewInterface) => {
    const { t } = useTranslation();
    const { state } = React.useContext(GoFlyerAppContext);

    const [processCompleted, setProcessCompleted] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(true);

    const canvasRef = useRef<CanvasComponentHandle>(null);

    // Sort images by flyerIndex; store only their URLs in sorted order
    const sortedImageList = useMemo(() => {
      return (
        flyer.gfImages
          ?.sort((a, b) => (a.flyerIndex || 0) - (b.flyerIndex || 0))
          .map(val => val.src) || []
      );
    }, [flyer.gfImages]);

    // Mark that images have finished loading
    const handleJoinsImages = useCallback(() => {
      setProcessCompleted(true);
      setIsLoading(false);
    }, []);

    /**
     * Calculate and add circular hotspots to the canvas for each flyer item.
     * Performs the following steps:
     * 1. Sort flyer images by flyerIndex.
     * 2. Calculate scaled heights for each image when fitted to the canvas width.
     * 3. Compute cumulative vertical offsets for multi-page layouts.
     * 4. Group flyer items by their page.
     * 5. For each page, compute the scale factors and determine each hotspot's center and radius.
     *
     * @function addCircles
     */
    const addCircles = useCallback(() => {
      // Guard: ensure canvas reference, flyer items, and images are available.
      if (
        !canvasRef.current ||
        !flyer?.flyerItemList?.flyerItems ||
        !flyer?.gfImages?.length
      ) {
        return;
      }

      // Retrieve the current canvas width.
      const canvasWidth = canvasRef.current.getCanvasWidth() ?? 0;

      // Step 1: Sort images by flyerIndex to maintain the order.
      const sortedFlyerImages = [...flyer.gfImages].sort(
        (a, b) => (a.flyerIndex || 0) - (b.flyerIndex || 0),
      );

      // Step 2: Determine the scaled height of each image when its width is fit to the canvas.
      const scaledHeights = sortedFlyerImages.map(img => {
        const originalW = img.width || 1;
        const originalH = img.height || 1;
        const scaleX = canvasWidth / originalW; // Scale factor based on width.
        // Calculate new height to preserve aspect ratio.
        return Math.round(originalH * scaleX);
      });

      // Step 3: Build cumulative vertical offsets so we know where each page starts.
      const cumulativeHeights = scaledHeights.reduce((acc, h, i) => {
        acc[i] = (acc[i - 1] || 0) + h;
        return acc;
      }, [] as number[]);

      // Step 4: Group flyer items by their flyerPage property.
      const { flyerItems, ..._FlyerItemList } = flyer.flyerItemList;
      const groupedItems = flyerItems
        ?.filter(val => val.coordinate)
        .reduce((acc, item) => {
          const pageIndex = item.coordinate?.flyerPage ?? 0;
          if (!acc[pageIndex]) acc[pageIndex] = [];
          acc[pageIndex].push(item);
          return acc;
        }, {} as Record<number, GfFlyerItemDto[]>);

      // Sort page indices to process in order.
      const sortedPages = Object.keys(groupedItems)
        .map(Number)
        .sort((a, b) => a - b);

      // Step 5: For each page compute scale and offset; then draw circles.
      sortedPages.forEach((pageIndex, i) => {
        const items = groupedItems[pageIndex];
        if (!items) return;

        // Use the corresponding image dimensions for current page.
        const originalW = sortedFlyerImages[i].width || 1;
        const originalH = sortedFlyerImages[i].height || 1;

        // Scale factor for width fitting the canvas.
        const scaleX = canvasWidth / originalW;
        const scaleY = scaleX; // Maintain aspect ratio.

        // Vertical offset based on cumulative height from prior pages.
        const offsetY = i > 0 ? cumulativeHeights[i - 1] : 0;

        items.forEach(item => {
          const coord = item.coordinate;
          if (!coord?.x || !coord?.y || !coord?.component) return;

          // Get original dimensions of the interactive area from the flyer.
          const itemW = coord.component.width || 0;
          const itemH = coord.component.height || 0;

          // Scale coordinates and dimensions based on computed factors.
          const scaledX = coord.x * scaleX;
          const scaledY = coord.y * scaleY;
          const scaledWidth = itemW * scaleX;
          const scaledHeight = itemH * scaleY;

          // Calculate center point of the hotspot.
          const centerX = scaledX + scaledWidth / 2;
          const centerY = offsetY + scaledY + scaledHeight / 2;

          // Determine the hotspot radius with reasonable bounds.
          const maxRadius = Math.min(scaledWidth, scaledHeight) / 1.8;
          const minRadius = 20;
          const radius = Math.max(
            minRadius,
            Math.min(maxRadius, scaledHeight / 2),
          );

          // Draw the circle if the computed radius is valid.
          if (radius > 0) {
            const inShoppingList = Boolean(
              checkIfFlyerItemInShoppingList(
                item,
                state.customer.gfShoppingList,
              ),
            );
            // Call the canvas method to draw the circle hotspot.
            canvasRef.current?.drawCircleAt(
              centerX,
              centerY,
              radius,
              inShoppingList ? '#ef8200' : 'transparent',
              {
                ...item,
                flyerItemList: _FlyerItemList as GfFlyerItemList,
              },
            );
          }
        });
      });
    }, [flyer, state.customer.gfShoppingList]);

    // Once images are ready, mark process completed
    useEffect(() => {
      if (flyer.gfImages?.length && !processCompleted) {
        handleJoinsImages();
      }
    }, [flyer.gfImages, processCompleted, handleJoinsImages]);

    /**
     * Handler invoked when images are loaded.
     * Marks the process as completed, hides the spinner, and then adds canvas hotspots.
     *
     * @function handleImagesLoaded
     */
    const handleImagesLoaded = useCallback(() => {
      setProcessCompleted(true);
      setIsLoading(false);

      // Delay adding circles to ensure canvas dimensions are updated.
      const timer = setTimeout(() => {
        addCircles();
      }, 100);
      return () => clearTimeout(timer);
    }, [addCircles]);

    // Show spinner until images are processed
    if (!processCompleted || isLoading) {
      return <Spinner />;
    }

    return (
      <div style={{ height: '100vh', position: 'relative' }}>
        <CustomHelmet
          title={`Goflyer - ${flyer.stores[0].merchant.name}`}
          image={flyer.gfImages?.[0]?.src || ''}
          description={t(messages.flyerViewIdDescription())}
          keywords={t(messages.flyerViewIdKeywords())}
        />

        <TopDivView
          validStartDate={flyer?.validStartDate}
          validEndDate={flyer?.validEndDate}
          handleShare={handleShare}
          store={flyer.stores[0]}
          marginTop={50}
        />

        <CanvasDrawComponentMobile
          ref={canvasRef}
          imageList={sortedImageList}
          onImagesLoaded={handleImagesLoaded}
        />
      </div>
    );
  },
);
