import * as React from 'react';
import styled from 'styled-components/macro';
import { GoFlyerAppContext } from 'app/store/context';
import { setCustomer } from 'app/store/reducer';
import { useHistory } from 'react-router-dom';
import { apiService } from 'utils/api';
import { GfCustomerDtoWithLogin } from 'app/store/state';
import { localstorageGet } from 'utils/localstorage';
import SignInPageUI from 'app/mobile-desktop-common-components/SignIn';
import LoggedInProfileUI from 'app/mobile-desktop-common-components/LoggedInProfileUI';
import CustomHelmet from 'app/components/Helmet';
import { useTranslation } from 'react-i18next';
import { messages } from 'locales/messages';
interface Props {}

/**
 * This component is using https://github.com/capawesome-team/capacitor-firebase/tree/main/packages/authentication
 * @param props
 * @returns
 */ export const SigninPageMobile: React.FunctionComponent<Props> = props => {
  const { t } = useTranslation();

  const { state, dispatch } = React.useContext(GoFlyerAppContext);
  const [loginSuccessful, setLoginSuccessful] = React.useState(false);

  React.useEffect(() => {
    if (loginSuccessful) {
      history.push('/flyers');
    }
  }, [loginSuccessful]);

  const history = useHistory();

  const loginCallBackend = async (authResult: any) => {
    const customer = localstorageGet('customer') as GfCustomerDtoWithLogin;
    const registerResponse = await apiService.authControllerRegister(
      authResult,
      customer.id,
      {},
    );

    const user = await apiService.appControllerGetRequestUser({
      headers: {
        Authorization: `Bearer ${registerResponse?.token}`,
      },
    });
    let userWithLogin = user as GfCustomerDtoWithLogin;
    loginSuccessCallback(userWithLogin, registerResponse);
  };

  const loginSuccessCallback = async (
    loggedInCustomer: GfCustomerDtoWithLogin,
    registerResponse: any,
  ) => {
    /**
     * 1. If the user does not have a notification token but previously allowed notifications, we need to transfer the previous notification token to this user. We need to update the database to remove the previous user's notification token, otherwise, this user will receive notifications twice.
     * 2. for this logic, loggedInCustomer.id !== state.customer.id, we only want to move the token if the new logged in customer is different than the previous one. If the new logged in user and the current state customer have the same id, it means this is the returned customer. There is no need to move the token again
     * 3. we also have similar logic on the backend to deduplicateNotificationToken
     */
    if (
      loggedInCustomer.id !== state.customer.id &&
      state.customer &&
      state.customer.notificationToken
    ) {
      /**
       * We unset the initial user notification token in the db. This token is obtained when customer open the mobile app for the first time
       */
      await apiService.gfCustomerControllerUpdate(
        { notificationToken: undefined },
        state.customer.id,
      );
      /**
       * transfer this token to the new logged in customer
       */
      await apiService.gfCustomerControllerUpdate(
        { notificationToken: state.customer.notificationToken },
        loggedInCustomer.id,
      );
    }

    dispatch(
      setCustomer({
        ...loggedInCustomer,
        isLogin: true,
        access_token: registerResponse.token,
        notificationToken: state?.customer?.notificationToken,
      }),
    );
    setLoginSuccessful(true);
  };

  return (
    <>
      <CustomHelmet
        title={`Goflyer - ${
          state.customer.email || state.customer.phoneNumber
            ? `Profile - ${state.customer.firstName}`
            : t(messages.Sign_In())
        }`}
        description={t(messages.signInDescription())}
        keywords={t(messages.signInKeywords())}
      />

      <Div data-testid="signinpagemobile-test-id">
        <MainContent>
          {state.customer.isLogin ? (
            <LoggedInProfileUI customer={state.customer} />
          ) : (
            <SignInPageUI loginCallBackend={loginCallBackend} />
          )}
        </MainContent>
      </Div>
    </>
  );
};

const Div = styled.div`
  margin: 100px 20px;
  line-height: 30px;
`;

const MainContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  margin: 0;
  height: auto;
`;
