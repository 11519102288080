// Path: ./src/app/pages/AboutPage/index.tsx
/**
 *
 * AboutPage
 *
 */
import * as React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import * as analytics from '../../../utils/analytics';
import CustomHelmet from 'app/components/Helmet';
import { messages } from 'locales/messages';

interface Props {}

export const AboutPage: React.FunctionComponent<Props> = props => {
  const { t, i18n } = useTranslation();
  React.useEffect(() => {
    analytics.view_about_page();
  }, []);

  return (
    <>
      <CustomHelmet
        title={`Goflyer - ${t(messages.About_Us())}`}
        description={t(messages.aboutDescription())}
        keywords={t(messages.aboutKeywords())}
      />
      <Div data-testid="aboutpage-test-id">
        {i18n.language === 'zh' ? (
          <div>
            GoFlyer
            是一款为用户专门设计的提供大多伦多地区各种优惠产品的App，每周提供最快最全面的各类传单及精选独家特价产品，并提供中英文版本方便用户使用。我们同时以用户所在位置为中心，最快速的显示出附近所有特价传单及最适合你的特价产品。并推荐给用户精选的各种优惠。GoFlyer
            团队同时每天搜索各类商家的及时更新特价推荐给用户，并与各类商家直接沟通，获得独家优惠，帮GoFlyer用户每日生活省钱多多！
          </div>
        ) : (
          <div>
            GoFlyer is the App helps users save on daily shoppings , we focus
            more on Asian and ethnic stores, delivering flyers and super deals
            on time. We aggregate deals from stores all over GTA into the
            GoFlyer app. We also list all promotions and flyers near you on the
            map. That helps you easily find all deals nearby. Our team directly
            reaches merchants to get exclusive deals for our users. The goal is
            to help you find the best and most relevant deals with a few clicks
            any time.{' '}
          </div>
        )}
      </Div>
    </>
  );
};

const Div = styled.div`
  margin: 70px 20px;
  line-height: 30px;
`;
