import React, {
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useState,
  useCallback,
} from 'react';
import { Spinner } from 'app/components/Spinner';
import { GfFlyerItemDto } from '@swagger/typescript-fetch-goflyer';
import { useHistory } from 'react-router-dom';
import { FlyerDetailViewCanvasV1 } from 'app/components/FlyerDetailView/FlyerDetailViewCanvas';

/**
 * Represents a circular hotspot on the canvas.
 * @typedef {Object} Circle
 * @property {number} x - The x coordinate.
 * @property {number} y - The y coordinate.
 * @property {number} radius - The circle radius.
 * @property {string} color - The stroke color.
 * @property {number} [lineWidth] - Optional line width.
 * @property {number} [minLineWidth] - Minimum line width for drawing effect.
 * @property {number} [maxLineWidth] - Maximum line width for drawing effect.
 * @property {GfFlyerItemDto} [data] - Associated data.
 */
type Circle = {
  x: number;
  y: number;
  radius: number;
  color: string;
  lineWidth?: number;
  minLineWidth?: number;
  maxLineWidth?: number;
  data?: GfFlyerItemDto;
};

/**
 * Represents a rectangular hotspot on the canvas.
 * @typedef {Object} Square
 * @property {number} x - The x coordinate of the center.
 * @property {number} y - The y coordinate of the center.
 * @property {number} width - The width of the square.
 * @property {number} height - The height of the square.
 * @property {string} color - The border color.
 * @property {GfFlyerItemDto} [data] - Associated data.
 */
type Square = {
  x: number;
  y: number;
  width: number;
  height: number;
  color: string;
  data?: GfFlyerItemDto;
};

/**
 * Props for the CanvasDrawComponent.
 * @typedef {Object} CanvasComponentProps
 * @property {string[]} imageList - List of image URLs.
 * @property {() => void} [onImagesLoaded] - Callback when images are loaded.
 */
type CanvasComponentProps = {
  imageList: string[];
  onImagesLoaded?: () => void;
};

/**
 * Exposed handle for controlling canvas drawing.
 * @typedef {Object} CanvasComponentHandle
 * @property {Function} drawCircleAt - Draws a circle at given coordinates.
 * @property {Function} drawSquareAt - Draws a square at given coordinates.
 * @property {Function} getCanvasHeight - Returns the canvas height.
 * @property {Function} handleItemClick - Callback for item click.
 * @property {Function} getCanvasWidth - Returns the canvas width.
 */
export type CanvasComponentHandle = {
  drawCircleAt: (
    x: number,
    y: number,
    radius: number,
    color?: string,
    data?: GfFlyerItemDto,
  ) => void;
  drawSquareAt: (
    x: number,
    y: number,
    width: number,
    height: number,
    color?: string,
    data?: GfFlyerItemDto,
  ) => void;
  getCanvasHeight: () => number;
  handleItemClick?: (item: GfFlyerItemDto) => void;
  getCanvasWidth: () => number;
};

/**
 * A mobile canvas drawing component that renders images and hotspots.
 * @component
 * @param {CanvasComponentProps} props - Component props.
 * @param {React.Ref<CanvasComponentHandle>} ref - Ref to expose methods to parent.
 */
const CanvasDrawComponentMobile = forwardRef<
  CanvasComponentHandle,
  CanvasComponentProps
>(({ imageList: imageSrcList, onImagesLoaded }, ref) => {
  const history = useHistory();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const offscreenCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const contextRef = useRef<CanvasRenderingContext2D | null>(null);
  const parentRef = useRef<HTMLDivElement | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [initError, setInitError] = useState<string | null>(null);
  const [circles, setCircles] = useState<Circle[]>([]);
  const [squares, setSquares] = useState<Square[]>([]);
  const [canvasHeight, setCanvasHeight] = useState(832);
  const imagesRef = useRef<
    { img: HTMLImageElement; scaledWidth: number; scaledHeight: number }[]
  >([]);
  const [isCanvasReady, setIsCanvasReady] = useState(false);
  const [hoveredItem, setHoveredItem] = useState<{
    x: number;
    y: number;
    width?: number;
    height?: number;
    radius?: number;
  } | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const dragStartTime = useRef<number>(0);
  const clickCount = useRef<number>(0);
  const clickDelay = 250;

  useImperativeHandle(ref, () => ({
    drawCircleAt,
    drawSquareAt,
    getCanvasHeight: () => canvasHeight,
    handleItemClick: (item: GfFlyerItemDto) => {
      history.push({
        pathname: `/promotiondetail`,
        search: `flyerItemId=${item.id}`,
      });
    },
    getCanvasWidth: () => canvasRef.current?.width || 0,
  }));

  /**
   * Draw all circles onto the canvas.
   */
  const drawCircles = useCallback(() => {
    if (!contextRef.current || !isCanvasReady) return;
    const ctx = contextRef.current;

    circles.forEach(circle => {
      // Check if the circle is hovered to adjust style.
      const isHovered =
        hoveredItem?.x === circle.x && hoveredItem?.y === circle.y;

      if (isHovered) {
        ctx.save();
        // Add glow effect for hovered circle.
        ctx.shadowColor = '#f58220';
        ctx.shadowBlur = 15;
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
        ctx.beginPath();
        ctx.arc(circle.x, circle.y, circle.radius + 2, 0, 2 * Math.PI);
        ctx.fillStyle = 'rgba(245, 130, 32, 0.1)';
        ctx.fill();
      }

      // Calculate drawing parameters for segmented outline effect.
      const penAngle = Math.PI / 4;
      const minLineWidth = isHovered ? 3 : 2;
      const maxLineWidth = isHovered ? 12 : 8;
      const segments = 120;
      const gapAngle = 0.2;
      const totalAngle = 2 * Math.PI - gapAngle;

      // Draw segmented arc with varying line widths.
      for (let i = 0; i < segments; i++) {
        const t1 = (i / segments) * totalAngle;
        const t2 = ((i + 1) / segments) * totalAngle;
        const tangent1 = t1 + Math.PI / 2;
        const tangent2 = t2 + Math.PI / 2;
        const lw1 =
          minLineWidth +
          (maxLineWidth - minLineWidth) *
            Math.abs(Math.cos(tangent1 - penAngle));
        const lw2 =
          minLineWidth +
          (maxLineWidth - minLineWidth) *
            Math.abs(Math.cos(tangent2 - penAngle));
        const segmentLineWidth = (lw1 + lw2) / 2;

        ctx.strokeStyle = circle.color || '#000';
        ctx.lineWidth = segmentLineWidth;
        ctx.lineCap = 'round';

        ctx.beginPath();
        const x1 = circle.x + circle.radius * Math.cos(t1);
        const y1 = circle.y + circle.radius * Math.sin(t1);
        const x2 = circle.x + circle.radius * Math.cos(t2);
        const y2 = circle.y + circle.radius * Math.sin(t2);
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.stroke();
      }

      if (isHovered) {
        ctx.restore();
      }
    });
  }, [circles, isCanvasReady, hoveredItem]);

  /**
   * Draw all squares onto the canvas.
   */
  const drawSquares = useCallback(() => {
    if (!contextRef.current || !isCanvasReady) return;
    const ctx = contextRef.current;

    squares.forEach(square => {
      const isHovered =
        hoveredItem?.x === square.x && hoveredItem?.y === square.y;
      ctx.save();

      if (isHovered) {
        // Draw background highlight for hovered square.
        ctx.shadowColor = '#f58220';
        ctx.shadowBlur = 15;
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
        ctx.fillStyle = 'rgba(245, 130, 32, 0.1)';
        ctx.fillRect(
          square.x - square.width / 2 - 2,
          square.y - square.height / 2 - 2,
          square.width + 4,
          square.height + 4,
        );
      }

      // Draw square border.
      ctx.strokeStyle = square.color;
      ctx.lineWidth = isHovered ? 6 : 4;
      ctx.beginPath();
      ctx.strokeRect(
        square.x - square.width / 2,
        square.y - square.height / 2,
        square.width,
        square.height,
      );
      ctx.restore();
    });
  }, [squares, isCanvasReady, hoveredItem]);

  /**
   * Redraws the entire canvas, including the offscreen canvas, circles, and squares.
   */
  const redrawCanvas = useCallback(() => {
    if (!contextRef.current || !offscreenCanvasRef.current || !isCanvasReady) {
      return;
    }
    const ctx = contextRef.current;
    const offscreenCanvas = offscreenCanvasRef.current;

    // Clear the canvas before drawing new frame.
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.drawImage(offscreenCanvas, 0, 0);

    // Draw drawn circles and squares.
    drawCircles();
    drawSquares();
  }, [drawCircles, drawSquares, isCanvasReady]);

  /**
   * Draws a circular hotspot at the specified coordinates.
   * This function adds a new circle to the canvas.
   *
   * @param {number} x - X coordinate.
   * @param {number} y - Y coordinate.
   * @param {number} radius - Circle radius.
   * @param {string} [color='#f58220'] - Stroke color.
   * @param {GfFlyerItemDto} [data] - Associated data.
   */
  const drawCircleAt = useCallback(
    (
      x: number,
      y: number,
      radius: number,
      color = '#f58220',
      data?: GfFlyerItemDto,
    ) => {
      if (isNaN(x) || isNaN(y) || isNaN(radius)) {
        console.error('Invalid circle coords/radius:', { x, y, radius });
        return;
      }
      if (contextRef.current && isCanvasReady) {
        const ctx = contextRef.current;
        ctx.save();
        ctx.beginPath();
        ctx.arc(x, y, radius, 0, 2 * Math.PI);
        ctx.strokeStyle = color;
        ctx.lineWidth = 4;
        ctx.stroke();
        ctx.restore();
      }

      const newCircle: Circle = {
        x,
        y,
        radius,
        color,
        data,
        minLineWidth: 2,
        maxLineWidth: 8,
      };

      // Update state without duplicating similar circles.
      setCircles(prevCircles => {
        const isDuplicate = prevCircles.some(
          c => c.x === x && c.y === y && c.data?.id === data?.id,
        );
        return isDuplicate ? prevCircles : [...prevCircles, newCircle];
      });

      // Redraw the canvas to include the new circle.
      requestAnimationFrame(() => {
        redrawCanvas();
      });
    },
    [isCanvasReady, redrawCanvas],
  );

  /**
   * Draws a rectangular hotspot at the specified coordinates.
   * This function adds a new square to the canvas.
   *
   * @param {number} x - X coordinate.
   * @param {number} y - Y coordinate.
   * @param {number} width - Square width.
   * @param {number} height - Square height.
   * @param {string} [color='#f58220'] - Border color.
   * @param {GfFlyerItemDto} [data] - Associated data.
   */
  const drawSquareAt = useCallback(
    (
      x: number,
      y: number,
      width: number,
      height: number,
      color = '#f58220',
      data?: GfFlyerItemDto,
    ) => {
      const newSquare: Square = { x, y, width, height, color, data };
      setSquares(prevSquares => [...prevSquares, newSquare]);
      requestAnimationFrame(() => {
        redrawCanvas();
      });
    },
    [redrawCanvas],
  );

  // Adjust canvas height to parent’s height
  useEffect(() => {
    if (parentRef.current) {
      const parentHeight = parentRef.current.offsetHeight - 80;
      setCanvasHeight(parentHeight);
      if (canvasRef.current && contextRef.current) {
        canvasRef.current.style.width = '100%';
        canvasRef.current.style.height = `${parentHeight}px`;
      }
    }
  }, []);

  // Load images into offscreen canvas
  useEffect(() => {
    if (!imageSrcList.length) return;

    const canvas = canvasRef.current;
    const offscreenCanvas = offscreenCanvasRef.current;
    if (!canvas || !offscreenCanvas) {
      setInitError('Canvas initialization failed');
      return;
    }

    const context = canvas.getContext('2d');
    const offscreenContext = offscreenCanvas.getContext('2d');
    if (!context || !offscreenContext) {
      setInitError('Canvas context initialization failed');
      return;
    }

    contextRef.current = context;
    setIsLoading(true);
    setIsCanvasReady(false);

    let loadedImagesCount = 0;
    let hasError = false;
    const totalImages = imageSrcList.length;
    imagesRef.current = [];
    let totalHeight = 0; // vertical stacking height

    const handleAllImagesLoaded = () => {
      if (hasError) return;

      // Get the maximum width from all scaled images
      const maxWidth = Math.max(
        ...imagesRef.current.map(({ scaledWidth }) => scaledWidth),
      );

      offscreenCanvas.width = maxWidth;
      offscreenCanvas.height = totalHeight;
      canvas.width = maxWidth;
      canvas.height = totalHeight;

      let yOffset = 0;
      imagesRef.current.forEach(({ img, scaledWidth, scaledHeight }) => {
        // Center horizontally
        const xOffset = (maxWidth - scaledWidth) / 2;
        offscreenContext.drawImage(
          img,
          xOffset,
          yOffset,
          scaledWidth,
          scaledHeight,
        );
        yOffset += scaledHeight;
      });

      setIsLoading(false);
      setIsCanvasReady(true);

      // Notify parent when images are fully loaded and rendered
      onImagesLoaded?.();
    };

    // Load each image
    imageSrcList.forEach((src, index) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = src;

      img.onload = () => {
        if (hasError) return;
        const aspectRatio = img.width / img.height;
        const scaledWidth = Math.min(window.innerWidth, img.width);
        const scaledHeight = scaledWidth / aspectRatio;
        imagesRef.current[index] = { img, scaledWidth, scaledHeight };
        totalHeight += scaledHeight;

        loadedImagesCount++;
        if (loadedImagesCount === totalImages) {
          handleAllImagesLoaded();
        }
      };

      img.onerror = () => {
        if (!hasError) {
          hasError = true;
          setInitError('Failed to load images');
          setIsLoading(false);
        }
      };
    });

    return () => {
      hasError = true;
    };
  }, [imageSrcList, canvasHeight, onImagesLoaded]);

  // Redraw canvas after images are ready
  useEffect(() => {
    if (isCanvasReady) {
      redrawCanvas();
    }
  }, [redrawCanvas, isCanvasReady]);

  const handleClickFunction = (e: MouseEvent) => {
    const canvas = canvasRef.current;
    if (!canvas || !isCanvasReady) return;

    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;

    const x = (e.clientX - rect.left) * scaleX;
    const y = (e.clientY - rect.top) * scaleY;

    // Check circles
    for (const circle of circles) {
      const dx = x - circle.x;
      const dy = y - circle.y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      if (distance <= circle.radius && circle.data) {
        history.push({
          pathname: `/promotiondetail`,
          search: `flyerItemId=${circle.data.id}`,
        });
        return;
      }
    }

    // Check squares first
    for (const square of squares) {
      const halfWidth = square.width / 2;
      const halfHeight = square.height / 2;
      if (
        x >= square.x - halfWidth &&
        x <= square.x + halfWidth &&
        y >= square.y - halfHeight &&
        y <= square.y + halfHeight &&
        square.data
      ) {
        history.push({
          pathname: `/promotiondetail`,
          search: `flyerItemId=${square.data.id}`,
        });
        return;
      }
    }
  };
  // Setup mouse events for click detection
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas || !isCanvasReady) return;

    const handleMouseDown = (e: MouseEvent) => {
      dragStartTime.current = Date.now();
      setIsDragging(true);
    };

    const handleMouseUp = (e: MouseEvent) => {
      setIsDragging(false);
    };
    const handleClick = (e: MouseEvent) => {
      const dragDuration = Date.now() - dragStartTime.current;
      if (isDragging || dragDuration > 150) {
        return;
      }
      clickCount.current++;
      setTimeout(() => {
        if (clickCount.current === 1) {
          handleClickFunction(e);
        } else if (clickCount.current === 2) {
        }
        clickCount.current = 0;
      }, clickDelay);
    };

    canvas.addEventListener('mousedown', handleMouseDown);
    canvas.addEventListener('mouseup', handleMouseUp);
    canvas.addEventListener('click', handleClick);

    return () => {
      canvas.removeEventListener('mousedown', handleMouseDown);
      canvas.removeEventListener('mouseup', handleMouseUp);
      canvas.removeEventListener('click', handleClick);
    };
  }, [circles, squares, isCanvasReady, isDragging, history]);

  // Handle mousemove for hover
  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!canvasRef.current || !isCanvasReady) return;
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      const scaleX = canvas.width / rect.width;
      const scaleY = canvas.height / rect.height;

      const x = (e.clientX - rect.left) * scaleX;
      const y = (e.clientY - rect.top) * scaleY;

      let isOverItem = false;

      // squares
      for (const square of squares) {
        const halfWidth = square.width / 2;
        const halfHeight = square.height / 2;
        if (
          x >= square.x - halfWidth &&
          x <= square.x + halfWidth &&
          y >= square.y - halfHeight &&
          y <= square.y + halfHeight
        ) {
          canvas.style.cursor = 'pointer';
          setHoveredItem({
            x: square.x,
            y: square.y,
            width: square.width,
            height: square.height,
          });
          isOverItem = true;
          break;
        }
      }

      // circles
      if (!isOverItem) {
        for (const circle of circles) {
          const dx = x - circle.x;
          const dy = y - circle.y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          if (distance <= circle.radius) {
            canvas.style.cursor = 'pointer';
            setHoveredItem({ x: circle.x, y: circle.y, radius: circle.radius });
            isOverItem = true;
            break;
          }
        }
      }

      if (!isOverItem) {
        canvas.style.cursor = 'default';
        setHoveredItem(null);
      }
    },
    [circles, squares, isCanvasReady],
  );

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas || !isCanvasReady) return;

    canvas.addEventListener('mousemove', handleMouseMove);
    return () => {
      canvas.removeEventListener('mousemove', handleMouseMove);
    };
  }, [handleMouseMove, isCanvasReady]);

  if (initError) {
    return <div>Error: {initError}</div>;
  }

  return (
    <div
      ref={parentRef}
      style={{
        overflowY: 'scroll',
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
      onMouseDown={e => e.preventDefault()}
      onClick={e => e.preventDefault()}
    >
      {isLoading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            zIndex: 1,
          }}
        >
          <Spinner />
        </div>
      )}
      <FlyerDetailViewCanvasV1>
        <canvas
          ref={canvasRef}
          style={{ display: isCanvasReady ? 'block' : 'none' }}
        />
        <canvas ref={offscreenCanvasRef} style={{ display: 'none' }} />
      </FlyerDetailViewCanvasV1>
    </div>
  );
});

export default CanvasDrawComponentMobile;
