// Path: ./src/app/pages/FlyerView/index_v1.tsx
/**
 *
 * FlyerView
 *
 */
import React, { memo } from 'react';
import { GfFlyerDto } from '@swagger/typescript-fetch-goflyer';
import { apiService } from '../../../utils/api';
import {} from 'utils/getQueryPara';
import { GoFlyerAppContext } from 'app/store/context';
import { Spinner } from 'app/components/Spinner';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as analytics from '../../../utils/analytics';
import { FlyerDetailViewWrapper } from '../../components/FlyerDetailView/index';
import { Share } from '@capacitor/share';
import useLocalizedDateFormatter from 'utils/hook/useLocalizedDateFormatter';
import CustomHelmet from 'app/components/Helmet';
import { useTranslation } from 'react-i18next';
import { messages } from 'locales/messages';
import { NewDrawFlyerView } from 'app/components/FlyerDetailView/newDrawFlyerView';
interface IParams {
  id: string;
}
export const FlyerView = memo(() => {
  const history = useHistory();
  const { t } = useTranslation();
  const Params = useParams<IParams>();
  const location = useLocation();
  const idx = Params.id.split('-');
  const flyerId = `${idx[0]}-${idx[1]}-${idx[2]}-${idx[3]}-${idx[4]}`;
  const [flyer, setFlyer] = React.useState<GfFlyerDto>();
  const { state } = React.useContext(GoFlyerAppContext);
  const validStartDate = useLocalizedDateFormatter(flyer?.validStartDate, {
    format: {
      month: 'short',
      day: 'numeric',
    },
  });
  const validEndDate = useLocalizedDateFormatter(flyer?.validEndDate);

  const handleShare = async (flyer: GfFlyerDto) => {
    analytics.share_flyer(flyer);
    await Share.share({
      title: flyer?.stores[0]?.merchant?.name,
      text: `${flyer?.stores[0]?.merchant?.name} Flyer ${validStartDate} - ${validEndDate}`,
      url: `https://goflyer.ca/flyerview/${flyer.id}-${encodeURIComponent(
        flyer.stores[0].merchant.name?.toLowerCase(),
      )}-flyer`, // TODO: will make goflyer.ca as an env variable later
      dialogTitle: 'Share with others',
    });
  };

  React.useEffect(() => {
    getFlyer();
  }, [state.showBanner]);

  const getFlyer = async () => {
    console.log(`location.state`, location.state);

    let storeId = 'unavailable';
    /**
     * TODO: using location.state is not a good idea. we should include storeId in the url
     */
    if (
      location.state &&
      location.state !== 'chinese' && //TODO: This is a temp fix for GOFBIT-1278
      location.state != 'english' &&
      location.state != 'french'
    ) {
      storeId = location.state as string;
    }
    const response = await apiService.gfFlyerControllerFindOneWithStoreId(
      flyerId,
      storeId,
    );
    analytics.view_flyer_page(response);
    setFlyer(response);
  };
  if (flyer === undefined || flyer.gfImages === undefined) {
    return <Spinner></Spinner>;
  }

  const allImagesHaveDimensions = flyer.gfImages.every(
    img => img.width && img.height && img.width > 0 && img.height > 0,
  );

  try {
    /**
     * when traffic coming from google, it usually return an expired flyer detail view, which is not very user friendly. so we want to redirect to a page where is shows all the flyers and promotions. then clicking that flyer, go to the up to date flyer.
     */

    const referrer = document.referrer;
    if (
      referrer &&
      referrer.indexOf('google') >= 0 &&
      new Date(flyer?.validEndDate).getTime() < new Date().getTime()
    ) {
      /**
       * this is because when user click the back button, we don't want users go back to expired flyer page, but go back to explore page
       */
      history.push(`/flyers`);
      history.push(`/storedetails/${flyer?.stores[0]?.id}`);
    }
  } catch (error) {
    console.log(`error`, error);
  }
  return (
    <>
      <CustomHelmet
        title={`Goflyer - ${flyer?.stores[0]?.merchant?.name}`}
        image={flyer?.gfImages[0]?.src}
        description={t(messages.flyerViewIdDescription())}
        keywords={t(messages.flyerViewIdKeywords())}
      />

      {allImagesHaveDimensions && flyer.flyerItemList ? (
        <NewDrawFlyerView
          flyer={flyer}
          handleShare={() => {
            return handleShare(flyer);
          }}
        />
      ) : (
        <FlyerDetailViewWrapper
          validStartDate={flyer.validStartDate}
          validEndDate={flyer.validEndDate}
          store={flyer?.stores[0]}
          handleShare={() => {
            return handleShare(flyer);
          }}
          metaData={flyer.metaData}
          gfImages={flyer.gfImages}
        />
      )}
    </>
  );
});
