// Path: ./src/app/components/FlyerDetailView/FlyerDetailView.tsx
/**
 *
 * FlyerView
 *
 */
import React, { memo } from 'react';
import { GfImageDto, GfStoreDto } from '@swagger/typescript-fetch-goflyer';
import {} from 'utils/getQueryPara';
import { Spinner } from 'app/components/Spinner';
import * as analytics from '../../../utils/analytics';
import { TopDivView } from './TopDiv';

import {
  FlyerViewImageList,
  GridSplitFlyerViewImageList,
} from 'app/components/FlyerViewImgeList';
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from 'react-zoom-pan-pinch';
import { GoFlyerAppContext } from 'app/store/context';
import CustomHelmet from '../Helmet';
import { messages } from 'locales/messages';
import { useTranslation } from 'react-i18next';

interface FlyerDetailViewInterface {
  handleShare: (params: any) => Promise<void>;
  store: GfStoreDto;
  gfImages: GfImageDto[];
  validStartDate?: Date;
  validEndDate?: Date;
  metaData?: string;
}
export const FlyerDetailViewV1 = memo((props: FlyerDetailViewInterface) => {
  const { t } = useTranslation();

  const [iframeHeight, setIframeHeight] = React.useState(0);
  const [iframeWidth, setIframeWidth] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const [imageLoadedCount, setImageLoadedCount] = React.useState(0);
  const [totalImageHeight, setTotalImageHeight] = React.useState(0);
  const [showGrabbing, setShowGrabbing] = React.useState<boolean>(false);
  const transformComponentRef = React.useRef<ReactZoomPanPinchRef>(null!);
  const { state } = React.useContext(GoFlyerAppContext);

  const setIframeHeightFunc = () => {
    let HeaderANavWrapperHeight = 0;
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    if (document.querySelector('.HeaderANavWrapper') !== null) {
      HeaderANavWrapperHeight =
        document.querySelector('.HeaderANavWrapper')!.clientHeight;
    }
    setIframeHeight(windowHeight - HeaderANavWrapperHeight - 55);
    setIframeWidth(windowWidth);
  };

  function getDistance(deltaPoint1, deltaPoint2) {
    return Math.sqrt(
      (deltaPoint1.x - deltaPoint2.x) ** 2 +
        (deltaPoint1.y - deltaPoint2.y) ** 2,
    );
  }

  function getCenter(deltaPoint1, deltaPoint2) {
    return {
      x: (deltaPoint1.x + deltaPoint2.x) / 2,
      y: (deltaPoint1.y + deltaPoint2.y) / 2,
    };
  }

  const customMoveAndZoomImage = elementOfImage => {
    let deltaLast: any = null;
    let lastDist: any = 0;
    let startContext: any = {};

    elementOfImage.addEventListener('touchstart', event => {
      if (event?.touches?.length === 2) {
        event.preventDefault();

        startContext.twoTouchPanning = true;
      }
    });

    elementOfImage.addEventListener('touchmove', event => {
      if (event?.touches?.length === 2) {
        event.preventDefault();
        //clearing panning of default Single finger
        transformComponentRef.current.instance.clearPanning(event);

        let touch1 = event.touches[0];
        let touch2 = event.touches[1];

        // value of 1st touched finger
        let deltaPoint1 = {
          x: touch1.clientX,
          y: touch1.clientY,
        };

        // value of 2nd touched finger
        let deltaPoint2 = {
          x: touch2.clientX,
          y: touch2.clientY,
        };

        if (!deltaLast) {
          deltaLast = getCenter(deltaPoint1, deltaPoint2);
          return;
        }

        // getting center of between two points/fingers
        let newDelta = getCenter(deltaPoint1, deltaPoint2);

        // calculating distance between two points/fingers
        let dist = getDistance(deltaPoint1, deltaPoint2);

        if (!lastDist) {
          lastDist = dist;
        }

        // calculating scale by dividing dist and lastDistance and multiplying current scale
        let scale =
          (dist / lastDist) * transformComponentRef.current.state.scale;

        // applying limit so scale doesn't zoom out or zoom in too much
        if (scale > 1 && scale <= 10) {
        } else if (scale <= 1) {
          scale = 1;
        } else if (scale > 10) {
          scale = 10;
        }

        // calculating how much moved on x and y axis
        let dx = (newDelta.x - deltaLast.x) * 1.5;
        let dy = (newDelta.y - deltaLast.y) * 1.5;

        // calculating new X and Y position for Image
        let newPos = {
          x:
            newDelta.x -
            ((newDelta.x - transformComponentRef.current.state.positionX) /
              transformComponentRef.current.state.scale) *
              scale +
            dx,
          y:
            newDelta.y -
            ((newDelta.y - transformComponentRef.current.state.positionY) /
              transformComponentRef.current.state.scale) *
              scale +
            dy,
        };

        transformComponentRef.current.setTransform(
          newPos.x,
          newPos.y,
          scale,
          0.1,
          'linear',
        );
        lastDist = dist;
        deltaLast = newDelta;
      }
    });

    elementOfImage.addEventListener('touchend', event => {
      lastDist = 0;
      deltaLast = null;

      if (startContext?.twoTouchPanning) {
        event.preventDefault();

        transformComponentRef.current.instance.clearPanning(event);

        // if scale was above 6 scale performing scale down to 6
        if (transformComponentRef.current.state.scale > 6) {
          let scale = 6;
          let newPos = {
            x:
              (transformComponentRef.current.state.positionX /
                transformComponentRef.current.state.scale) *
              scale,
            y:
              (transformComponentRef.current.state.positionY /
                transformComponentRef.current.state.scale) *
              scale,
          };

          let imageRect: any =
            transformComponentRef.current.instance.contentComponent?.getBoundingClientRect();
          let containerRect: any =
            transformComponentRef.current.instance.wrapperComponent?.getBoundingClientRect();
          let correctedCords = { x: newPos.x, y: newPos.y };
          if (imageRect.left > containerRect.left)
            correctedCords.x -= imageRect.left - containerRect.left;
          if (imageRect.right < containerRect.right)
            correctedCords.x -= imageRect.right - containerRect.right;
          if (imageRect.top > containerRect.top)
            correctedCords.y -= imageRect.top - containerRect.top;
          if (imageRect.bottom < containerRect.bottom)
            correctedCords.y -= imageRect.bottom - containerRect.bottom;
          setTimeout(() => {
            transformComponentRef.current.setTransform(
              correctedCords.x,
              correctedCords.y,
              scale,
              200,
              'easeOut',
            );
          }, 100);
        } else {
          // calculating boundaries of image if it was out of scope bring it back to scope
          let imageRect: any =
            transformComponentRef.current.instance.contentComponent?.getBoundingClientRect();
          let containerRect: any =
            transformComponentRef.current.instance.wrapperComponent?.getBoundingClientRect();
          let correctedCords = {
            x: transformComponentRef.current.state.positionX,
            y: transformComponentRef.current.state.positionY,
          };
          if (imageRect.left > containerRect.left)
            correctedCords.x -= imageRect.left - containerRect.left;
          if (imageRect.right < containerRect.right)
            correctedCords.x -= imageRect.right - containerRect.right;
          if (imageRect.top > containerRect.top)
            correctedCords.y -= imageRect.top - containerRect.top;
          if (imageRect.bottom < containerRect.bottom)
            correctedCords.y -= imageRect.bottom - containerRect.bottom;
          transformComponentRef.current.setTransform(
            correctedCords.x,
            correctedCords.y,
            transformComponentRef.current.state.scale,
            0.1,
            'linear',
          );
        }

        startContext.twoTouchPanning = false;
      }
    });
  };

  React.useEffect(() => {
    setIframeHeightFunc();

    let touchTime = 0;
    const element = document.querySelector('.WrapperZoomPinch');
    /**
     * for some reason, dblclick is not working in ios device
     */
    element?.addEventListener('click', event => {
      if (touchTime === 0) {
        // set first click
        touchTime = new Date().getTime();
      } else {
        // compare first click to this click and see if they occurred within double click threshold
        if (new Date().getTime() - touchTime < 400) {
          analytics.double_click_flyer();
          // double click occurred
          if (transformComponentRef.current.state.scale < 2) {
            transformComponentRef.current.zoomIn(1);
          } else {
            transformComponentRef.current.zoomOut(
              Math.round(transformComponentRef.current.state.scale),
            );
          }
          touchTime = 0;
        } else {
          // not a double click so set as a new first click
          touchTime = new Date().getTime();
        }
      }
    });

    // selecting image component
    const zoomElement = document.querySelector('.react-transform-component');
    customMoveAndZoomImage(zoomElement);
  }, [isLoading]);

  React.useEffect(() => {
    setIframeHeightFunc();
  }, [state.showBanner]);

  if (props.gfImages === undefined) {
    return <Spinner></Spinner>;
  }
  let ImageList: any[] | undefined;
  const onImageLoad = () => {
    // I put here because ios window.innerHeight is diffferent from the previous page, depending
    // on the address bar position
    setIframeHeightFunc();
    setImageLoadedCount(imageLoadedCount + 1);
    if (ImageList == null || props.gfImages === undefined) {
      return;
    }

    if (imageLoadedCount === props.gfImages.length - 1) {
      const allImageElements = document.querySelectorAll(
        '.flyerImageTag',
      ) as NodeListOf<HTMLImageElement>;
      const BottomDiv = document.querySelector('.BottomDiv') as HTMLDivElement;
      const BottomDivHeight = BottomDiv.clientHeight;

      let totalHeight = 0;
      for (let index = 0; index < allImageElements.length; index++) {
        const flyerImageElement = allImageElements[index];
        totalHeight += flyerImageElement.height;
      }
      if (totalHeight < BottomDivHeight) {
        setTotalImageHeight(BottomDivHeight);
      } else {
        setTotalImageHeight(height => {
          return height + totalHeight;
        });
      }

      setIsLoading(false);
    }
  };

  if (props.metaData && props.metaData === 'gridSplitImage') {
    /**
     * let's config rows and cols from server
     */
    ImageList = GridSplitFlyerViewImageList(props.gfImages, onImageLoad);
  } else {
    /**
     * we can remove this logic once all mobile app is higher than 1.1.38, after 1.1.38, all flyer view will use the GridSplitFlyerViewImageList logic
     */
    ImageList = FlyerViewImageList(props.gfImages, onImageLoad);
  }

  return (
    <div>
      <div
        style={{
          display: isLoading ? 'block' : 'none',
        }}
      >
        <Spinner></Spinner>
      </div>
      <div
        /**
         * we only allow user to move flyer after all image render, otherwise it will mis-calcualte the boundaries. so if user startContext scrolling immediately after the image render, it will show white space on the top
         */
        style={{
          visibility: isLoading ? 'hidden' : 'visible',
        }}
      >
        <TopDivView
          validStartDate={props?.validStartDate}
          validEndDate={props?.validEndDate}
          handleShare={props.handleShare}
          store={props.store}
          marginTop={50}
        ></TopDivView>
        <div className="BottomDiv">
          <TransformWrapper
            limitToBounds={true}
            initialScale={1}
            wheel={{
              wheelDisabled: true,
            }}
            onPanningStart={() => {
              setShowGrabbing(true);
            }}
            doubleClick={{ disabled: true }}
            pinch={{ disabled: true }}
            onPinchingStop={e => {
              if (e.state.scale > 5) {
                if (e.state.scale > 5.5) {
                  e.zoomOut(0.5);
                } else {
                  e.zoomOut(0.2);
                }
              }
            }}
            panning={{
              lockAxisX:
                transformComponentRef?.current?.state?.scale > 1 ? false : true,
            }}
            onPanningStop={e => {
              setShowGrabbing(false);
            }}
            maxScale={6}
            ref={transformComponentRef}
          >
            {({ zoomIn, zoomOut, resetTransform, state, ...rest }) => {
              return (
                <>
                  <TransformComponent
                    wrapperStyle={{ height: iframeHeight }}
                    contentStyle={{ height: totalImageHeight }}
                    wrapperClass={`WrapperZoomPinch ${
                      showGrabbing ? 'Flyer-Image' : ''
                    }`}
                  >
                    <div
                      style={{
                        width: iframeWidth,
                      }}
                      id="ZoomPinchImageElement"
                    >
                      {props.gfImages?.map(val => (
                        <img
                          key={val.id}
                          className="flyerImageTag"
                          style={{
                            width: '100%',
                          }}
                          onLoad={onImageLoad}
                          data-testid="flyer image"
                          src={val.src}
                          alt={val.id}
                        />
                      ))}
                    </div>
                  </TransformComponent>
                </>
              );
            }}
          </TransformWrapper>
        </div>
      </div>
    </div>
  );
});
