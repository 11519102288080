import React, { memo } from 'react';
import {} from 'utils/getQueryPara';
import * as analytics from '../../../utils/analytics';
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from 'react-zoom-pan-pinch';
import { GoFlyerAppContext } from 'app/store/context';

interface FlyerDetailViewInterface {
  children: React.ReactNode;
}
export const FlyerDetailViewCanvasV1 = memo(
  ({ children }: FlyerDetailViewInterface) => {
    const [iframeHeight, setIframeHeight] = React.useState(0);
    const [iframeWidth, setIframeWidth] = React.useState(0);
    const [showGrabbing, setShowGrabbing] = React.useState<boolean>(false);
    const transformComponentRef = React.useRef<ReactZoomPanPinchRef>(null!);
    const { state } = React.useContext(GoFlyerAppContext);

    const setIframeHeightFunc = () => {
      let HeaderANavWrapperHeight = 0;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      if (document.querySelector('.HeaderANavWrapper') !== null) {
        HeaderANavWrapperHeight =
          document.querySelector('.HeaderANavWrapper')!.clientHeight;
      }
      setIframeHeight(windowHeight - HeaderANavWrapperHeight - 55);
      setIframeWidth(windowWidth);
    };

    function getDistance(deltaPoint1, deltaPoint2) {
      return Math.sqrt(
        (deltaPoint1.x - deltaPoint2.x) ** 2 +
          (deltaPoint1.y - deltaPoint2.y) ** 2,
      );
    }

    function getCenter(deltaPoint1, deltaPoint2) {
      return {
        x: (deltaPoint1.x + deltaPoint2.x) / 2,
        y: (deltaPoint1.y + deltaPoint2.y) / 2,
      };
    }

    const customMoveAndZoomImage = elementOfImage => {
      let deltaLast: any = null;
      let lastDist: any = 0;
      let startContext: any = {};

      elementOfImage.addEventListener('touchstart', event => {
        if (event?.touches?.length === 2) {
          event.preventDefault();

          startContext.twoTouchPanning = true;
        }
      });

      elementOfImage.addEventListener('touchmove', event => {
        if (event?.touches?.length === 2) {
          event.preventDefault();
          //clearing panning of default Single finger
          transformComponentRef.current.instance.clearPanning(event);

          let touch1 = event.touches[0];
          let touch2 = event.touches[1];

          // value of 1st touched finger
          let deltaPoint1 = {
            x: touch1.clientX,
            y: touch1.clientY,
          };

          // value of 2nd touched finger
          let deltaPoint2 = {
            x: touch2.clientX,
            y: touch2.clientY,
          };

          if (!deltaLast) {
            deltaLast = getCenter(deltaPoint1, deltaPoint2);
            return;
          }

          // getting center of between two points/fingers
          let newDelta = getCenter(deltaPoint1, deltaPoint2);

          // calculating distance between two points/fingers
          let dist = getDistance(deltaPoint1, deltaPoint2);

          if (!lastDist) {
            lastDist = dist;
          }

          // calculating scale by dividing dist and lastDistance and multiplying current scale
          let scale =
            (dist / lastDist) * transformComponentRef.current.state.scale;

          // applying limit so scale doesn't zoom out or zoom in too much
          if (scale > 1 && scale <= 10) {
          } else if (scale <= 1) {
            scale = 1;
          } else if (scale > 10) {
            scale = 10;
          }

          // calculating how much moved on x and y axis
          let dx = (newDelta.x - deltaLast.x) * 1.5;
          let dy = (newDelta.y - deltaLast.y) * 1.5;

          // calculating new X and Y position for Image
          let newPos = {
            x:
              newDelta.x -
              ((newDelta.x - transformComponentRef.current.state.positionX) /
                transformComponentRef.current.state.scale) *
                scale +
              dx,
            y:
              newDelta.y -
              ((newDelta.y - transformComponentRef.current.state.positionY) /
                transformComponentRef.current.state.scale) *
                scale +
              dy,
          };

          transformComponentRef.current.setTransform(
            newPos.x,
            newPos.y,
            scale,
            0.1,
            'linear',
          );
          lastDist = dist;
          deltaLast = newDelta;
        }
      });

      elementOfImage.addEventListener('touchend', event => {
        lastDist = 0;
        deltaLast = null;

        if (startContext?.twoTouchPanning) {
          event.preventDefault();

          transformComponentRef.current.instance.clearPanning(event);

          // if scale was above 6 scale performing scale down to 6
          if (transformComponentRef.current.state.scale > 6) {
            let scale = 6;
            let newPos = {
              x:
                (transformComponentRef.current.state.positionX /
                  transformComponentRef.current.state.scale) *
                scale,
              y:
                (transformComponentRef.current.state.positionY /
                  transformComponentRef.current.state.scale) *
                scale,
            };

            let imageRect: any =
              transformComponentRef.current.instance.contentComponent?.getBoundingClientRect();
            let containerRect: any =
              transformComponentRef.current.instance.wrapperComponent?.getBoundingClientRect();
            let correctedCords = { x: newPos.x, y: newPos.y };
            if (imageRect.left > containerRect.left)
              correctedCords.x -= imageRect.left - containerRect.left;
            if (imageRect.right < containerRect.right)
              correctedCords.x -= imageRect.right - containerRect.right;
            if (imageRect.top > containerRect.top)
              correctedCords.y -= imageRect.top - containerRect.top;
            if (imageRect.bottom < containerRect.bottom)
              correctedCords.y -= imageRect.bottom - containerRect.bottom;
            setTimeout(() => {
              transformComponentRef.current.setTransform(
                correctedCords.x,
                correctedCords.y,
                scale,
                200,
                'easeOut',
              );
            }, 100);
          } else {
            // calculating boundaries of image if it was out of scope bring it back to scope
            let imageRect: any =
              transformComponentRef.current.instance.contentComponent?.getBoundingClientRect();
            let containerRect: any =
              transformComponentRef.current.instance.wrapperComponent?.getBoundingClientRect();
            let correctedCords = {
              x: transformComponentRef.current.state.positionX,
              y: transformComponentRef.current.state.positionY,
            };
            if (imageRect.left > containerRect.left)
              correctedCords.x -= imageRect.left - containerRect.left;
            if (imageRect.right < containerRect.right)
              correctedCords.x -= imageRect.right - containerRect.right;
            if (imageRect.top > containerRect.top)
              correctedCords.y -= imageRect.top - containerRect.top;
            if (imageRect.bottom < containerRect.bottom)
              correctedCords.y -= imageRect.bottom - containerRect.bottom;
            transformComponentRef.current.setTransform(
              correctedCords.x,
              correctedCords.y,
              transformComponentRef.current.state.scale,
              0.1,
              'linear',
            );
          }

          startContext.twoTouchPanning = false;
        }
      });
    };

    React.useEffect(() => {
      setIframeHeightFunc();

      let touchTime = 0;
      const element = document.querySelector('.WrapperZoomPinch');
      /**
       * for some reason, dblclick is not working in ios device
       */
      element?.addEventListener('click', event => {
        if (touchTime === 0) {
          // set first click
          touchTime = new Date().getTime();
        } else {
          // compare first click to this click and see if they occurred within double click threshold
          if (new Date().getTime() - touchTime < 400) {
            event.preventDefault();
            event.stopPropagation();
            analytics.double_click_flyer();
            // double click occurred
            if (transformComponentRef.current.state.scale < 2) {
              transformComponentRef.current.zoomIn(1);
            } else {
              transformComponentRef.current.zoomOut(
                Math.round(transformComponentRef.current.state.scale),
              );
            }
            touchTime = 0;
          } else {
            // not a double click so set as a new first click
            touchTime = new Date().getTime();
          }
        }
      });

      // selecting image component
      const zoomElement = document.querySelector('.react-transform-component');
      customMoveAndZoomImage(zoomElement);
    }, []);

    React.useEffect(() => {
      setIframeHeightFunc();
    }, [state.showBanner]);

    return (
      <div>
        <div>
          <div className="BottomDiv">
            <TransformWrapper
              limitToBounds={true}
              initialScale={1}
              wheel={{
                wheelDisabled: true,
              }}
              onPanningStart={() => {
                setShowGrabbing(true);
              }}
              doubleClick={{ disabled: true }}
              pinch={{ disabled: true }}
              onPinchingStop={e => {
                if (e.state.scale > 5) {
                  if (e.state.scale > 5.5) {
                    e.zoomOut(0.5);
                  } else {
                    e.zoomOut(0.2);
                  }
                }
              }}
              panning={{
                lockAxisX:
                  transformComponentRef?.current?.state?.scale > 1
                    ? false
                    : true,
              }}
              onPanningStop={e => {
                setShowGrabbing(false);
              }}
              maxScale={6}
              ref={transformComponentRef}
            >
              {({ zoomIn, zoomOut, resetTransform, state, ...rest }) => {
                return (
                  <>
                    <TransformComponent
                      wrapperStyle={{ height: iframeHeight }}
                      wrapperClass={`WrapperZoomPinch ${
                        showGrabbing ? 'Flyer-Image' : ''
                      }`}
                    >
                      <div
                        style={{
                          width: iframeWidth,
                        }}
                        id="ZoomPinchImageElement"
                      >
                        {children}
                      </div>
                    </TransformComponent>
                  </>
                );
              }}
            </TransformWrapper>
          </div>
        </div>
      </div>
    );
  },
);
